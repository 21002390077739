






































import Card from '@/components/cart/Card.vue'
import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Core
} from "@/store/core";
import {
    Product
} from "@/store/product";
import {
    City
} from "@/store/city";
import _  from "lodash";
@Component({
    components: { Card },
    computed: {},
})

export default class PostSaller extends Vue {

    products: any = null
    async created() {
        let products = await Core.getHttp(`/api/default/products/?product_type=1`)
        this.products =  products.results
        this.products.length = Math.min(this.products.length, 10);
    }

    ximg(file: any) {
        return (file) ? process.env.VUE_APP_SERVER + '/' + file : 'https://images.pexels.com/photos/4052387/pexels-photo-4052387.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
    }

}
