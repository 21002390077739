import { render, staticRenderFns } from "./BuySell.vue?vue&type=template&id=c05af548&scoped=true&"
import script from "./BuySell.vue?vue&type=script&lang=js&"
export * from "./BuySell.vue?vue&type=script&lang=js&"
import style0 from "./BuySell.vue?vue&type=style&index=0&id=c05af548&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c05af548",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VTab,VTabItem,VTabs,VToolbar,VToolbarTitle})
